// import React, { useState } from 'react';
// import PayslipTemplate from './PayslipTemplate';
// import './Templates.css';

// const convertNumberToWords = (num) => {
//   if (num === 0) return 'Zero';

//   const ones = [
//     'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
//     'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
//   ];

//   const tens = [
//     '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
//   ];

//   const convertChunk = (n) => {
//     let str = '';
//     if (n >= 100) {
//       str += ones[Math.floor(n / 100)] + ' Hundred';
//       n %= 100;
//     }
//     if (n >= 20) {
//       str += (str ? ' ' : '') + tens[Math.floor(n / 10)];
//       n %= 10;
//     }
//     if (n > 0) {
//       str += (str ? ' ' : '') + ones[n];
//     }
//     return str;
//   };

//   let result = '';
//   let chunkCount = 0;
//   while (num > 0) {
//     const chunk = num % 1000;
//     if (chunk > 0) {
//       result = convertChunk(chunk) + (chunkCount ? ' Thousand' : '') + (result ? ' ' + result : '');
//     }
//     num = Math.floor(num / 1000);
//     chunkCount++;
//   }
//   return result.trim();
// };

// const Templates = () => {
//   const [data, setData] = useState({
//     companyName: '',
//     address: '',
//     contactNumber: '',
//     logo: '',
//     salaryMonth: '',
//     employeeName: '',
//     designation: '',
//     department: '',
//     location: '',
//     employeeId: '',
//     bankName: '',
//     bankAccountNumber: '',
//     pan: '',
//     epfNumber: '',
//     esiNumber: '',
//     uanNumber: '',
//     basic: '',
//     hra: '',
//     da: '',
//     travelAllowance: '',
//     businessIncentive: '',
//     providentFund: '',
//     netPay: '',
//     amountInWords: '',
//     numberOfWorkingDays: '',
//     perDaySalary: '',
//     totalDaysInMonth: '30',
//     leaveDayRupees: '',
//     advanceSalary: '',
//   });

//   const handleChange = (e, field = null) => {
//     const { name, value } = e.target;
//     setData((prevData) => {
//       const updatedData = { ...prevData, [field || name]: value };

//       const numberOfWorkingDays = parseFloat(updatedData.numberOfWorkingDays) || 0;
//       const perDaySalary = parseFloat(updatedData.perDaySalary) || 0;
//       const totalDaysInMonth = parseFloat(updatedData.totalDaysInMonth) || 30;

//       const leaveDays = totalDaysInMonth - numberOfWorkingDays;
//       const basic = (30 * perDaySalary).toFixed(2);
//       const leaveDayRupees = (leaveDays * perDaySalary).toFixed(2);
//       const providentFund = (basic * 0.12).toFixed(2);

//       const hra = parseFloat(updatedData.hra) || 0;
//       const da = parseFloat(updatedData.da) || 0;
//       const travelAllowance = parseFloat(updatedData.travelAllowance) || 0;
//       const businessIncentive = parseFloat(updatedData.businessIncentive) || 0;

//       const totalEarnings = parseFloat(basic) + hra + da + travelAllowance + businessIncentive;
//       const totalDeductions = parseFloat(providentFund) + parseFloat(updatedData.advanceSalary) || 0;
//       const netPay = (totalEarnings - totalDeductions).toFixed(2);

//       return {
//         ...updatedData,
//         basic,
//         leaveDayRupees,
//         providentFund,
//         netPay,
//         amountInWords: convertNumberToWords(parseFloat(netPay)),
//         leaveDays
//       };
//     });
//   };

//   return (
//     <div className="templates">
//       <PayslipTemplate data={data} handleChange={handleChange} />
//     </div>
//   );
// };

// export default Templates;
import React, { useState } from 'react';
import PayslipTemplate from './PayslipTemplate';
import './Templates.css';

const convertNumberToWords = (num) => {
  if (num === 0) return 'Zero';

  const ones = [
    'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
    'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
  ];

  const tens = [
    '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
  ];

  const convertChunk = (n) => {
    let str = '';
    if (n >= 100) {
      str += ones[Math.floor(n / 100)] + ' Hundred';
      n %= 100;
    }
    if (n >= 20) {
      str += (str ? ' ' : '') + tens[Math.floor(n / 10)];
      n %= 10;
    }
    if (n > 0) {
      str += (str ? ' ' : '') + ones[n];
    }
    return str;
  };

  let result = '';
  let chunkCount = 0;
  while (num > 0) {
    const chunk = num % 1000;
    if (chunk > 0) {
      result = convertChunk(chunk) + (chunkCount ? ' Thousand' : '') + (result ? ' ' + result : '');
    }
    num = Math.floor(num / 1000);
    chunkCount++;
  }
  return result.trim();
};

const Templates = () => {
  const [data, setData] = useState({
    companyName: '',
    address: '',
    contactNumber: '',
    logo: '',
    salaryMonth: '',
    employeeName: '',
    designation: '',
    department: '',
    location: '',
    employeeId: '',
    bankName: '',
    bankAccountNumber: '',
    pan: '',
    epfNumber: '',
    esiNumber: '',
    uanNumber: '',
    basic: '',
    hra: '',
    da: '',
    travelAllowance: '',
    businessIncentive: '',
    providentFund: '',
    netPay: '',
    amountInWords: '',
    numberOfWorkingDays: '',
    perDaySalary: '',
    totalDaysInMonth: '30',
    leaveDayRupees: '',
    advanceSalary: '',
  });

  const handleChange = (e, field = null) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value) || 0; // Ensure numeric values

    setData((prevData) => {
      const updatedData = { ...prevData, [field || name]: value };

      const numberOfWorkingDays = parseFloat(updatedData.numberOfWorkingDays) || 0;
      const perDaySalary = parseFloat(updatedData.perDaySalary) || 0;
      const totalDaysInMonth = parseFloat(updatedData.totalDaysInMonth) || 30;

      const leaveDays = totalDaysInMonth - numberOfWorkingDays;
      const basic = (30 * perDaySalary).toFixed(2);
      const leaveDayRupees = (leaveDays * perDaySalary).toFixed(2);
      const providentFund = (basic * 0.12).toFixed(2);

      const hra = parseFloat(updatedData.hra) || 0;
      const da = parseFloat(updatedData.da) || 0;
      const travelAllowance = parseFloat(updatedData.travelAllowance) || 0;
      const businessIncentive = parseFloat(updatedData.businessIncentive) || 0;

      const totalEarnings = (parseFloat(basic) + hra + da + travelAllowance + businessIncentive).toFixed(2);
      const totalDeductions = ( parseFloat(updatedData.advanceSalary) + parseFloat(leaveDayRupees)).toFixed(2);
      const netPay = (parseFloat(totalEarnings) - parseFloat(totalDeductions)).toFixed(2);

      return {
        ...updatedData,
        basic,
        leaveDayRupees,
        providentFund,
        netPay,
        amountInWords: convertNumberToWords(parseFloat(netPay)),
        leaveDays
      };
    });
  };

  return (
    <div className="templates">
      <PayslipTemplate data={data} handleChange={handleChange} />
    </div>
  );
};

export default Templates;
