import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PayslipTemplateVTS from './Vts';
import PayslipTemplateVIS from './Vis';
import PayslipTemplateVetri from './Vetriacedamic';
import vis from './images/vistem.jpeg';
import vts from './images/vtstem.jpeg';
import va from './images/vatem.jpeg';

const PayslipTemplatesPage = ({ data, handleChange }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const navigate = useNavigate();

  const handleBackClick = () => {
    setSelectedTemplate(null);
  };

  const handlePreviewClick = () => {
    navigate('/preview', { state: { templateType: selectedTemplate, data } });
  };

  return (
    <div className="templates-page">
      {selectedTemplate === null ? (
        <div className="template-buttons">
          <div onClick={() => setSelectedTemplate('VTS')} className="template-option">
            <img src={vts} alt="VTS Template" />
          </div>
          <div onClick={() => setSelectedTemplate('VIS')} className="template-option">
            <img src={vis} alt="VIS Template" />
          </div>
          <div onClick={() => setSelectedTemplate('Vetri')} className="template-option">
            <img src={va} alt="Vetri Template" />
          </div>
        </div>
      ) : (
        <div className="template-content">
          {selectedTemplate === 'VTS' && <PayslipTemplateVTS data={data} handleChange={handleChange} />}
          {selectedTemplate === 'VIS' && <PayslipTemplateVIS data={data} handleChange={handleChange} />}
          {selectedTemplate === 'Vetri' && <PayslipTemplateVetri data={data} handleChange={handleChange} />}
          <button onClick={handleBackClick}>Back</button>
          <button onClick={handlePreviewClick}>Preview</button>
        </div>
      )}
    </div>
  );
};

export default PayslipTemplatesPage;
