import React from 'react';
import './Paysliptemplate.css';
import Vislogo from './images/vetri.png'
const PayslipTemplateVIS = ({ data, handleChange }) => {
  return (
    <div>

      {/* <div className="payslip">
      <h1 className='c-tit'>Vetri It Systems</h1>
        <div className="header">

          <div className="company-info">

            <div className='company-logo'>
              <img src={Vislogo}></img>
            </div>
            <div className='company-details'>
              <p className='comp-para'><span className='com-span'>Address:</span> Vetri It Park,Surandai</p>
              <p className='comp-para'><span className='com-span'>Contact No:</span> 91 8438558527</p>
              <p className='comp-para'><span className='com-span'>Website:</span> www.vetriit.com </p>
            </div>
          </div>
        </div>
        <h2 className='c-subtit'>
          Pay slip for the month of{' '}
          <input
            type="month"
            name="salaryMonth"
            value={data.salaryMonth || ''}
            onChange={handleChange}
          />
        </h2>
        <div className="employee-details">
          <div className="employee-details-col">
            <input
              type="text"
              name="employeeName"
              value={data.employeeName || ''}
              onChange={handleChange}
              placeholder="Employee Name"
            />
            <input
              type="text"
              name="designation"
              value={data.designation || ''}
              onChange={handleChange}
              placeholder="Designation"
            />
            <input
              type="text"
              name="department"
              value={data.department || ''}
              onChange={handleChange}
              placeholder="Department"
            />
            <input
              type="text"
              name="location"
              value={data.location || ''}
              onChange={handleChange}
              placeholder="Location"
            />
            <input
              type="text"
              name="employeeId"
              value={data.employeeId || ''}
              onChange={handleChange}
              placeholder="Employee ID"
            />
          </div>
          <div className="employee-details-col">
            <input
              type="text"
              name="bankName"
              value={data.bankName || ''}
              onChange={handleChange}
              placeholder="Bank Name"
            />
            <input
              type="text"
              name="bankAccountNumber"
              value={data.bankAccountNumber || ''}
              onChange={handleChange}
              placeholder="Bank Account Number"
            />
            <input
              type="text"
              name="pan"
              value={data.pan || ''}
              onChange={handleChange}
              placeholder="PAN"
            />
            <input
              type="text"
              name="epfNumber"
              value={data.epfNumber || ''}
              onChange={handleChange}
              placeholder="EPF Number"
            />
            <input
              type="text"
              name="esiNumber"
              value={data.esiNumber || ''}
              onChange={handleChange}
              placeholder="ESI Number"
            />
            <input
              type="text"
              name="uanNumber"
              value={data.uanNumber || ''}
              onChange={handleChange}
              placeholder="UAN Number"
            />
          </div>
        </div>
        <div className="salary-info">
          <h3 className='c-subtit'>Salary Information</h3>
          <input
            type="number"
            name="numberOfWorkingDays"
            value={data.numberOfWorkingDays || ''}
            onChange={handleChange}
            placeholder="Number of Working Days"
          />
          <input
            type="number"
            name="perDaySalary"
            value={data.perDaySalary || ''}
            onChange={handleChange}
            placeholder="Per Day Salary"
          />
        </div>
        <div className="earnings-deductions">
          <div className="earnings">
            <h3 className='c-subtit'>Earnings</h3>
            <input
              type="text"
              name="basic"
              value={data.basic || ''}
              onChange={handleChange}
              placeholder="Basic"
              readOnly
            />
            <input
              type="text"
              name="hra"
              value={data.hra || ''}
              onChange={handleChange}
              placeholder="HRA"
            />
            <input
              type="text"
              name="da"
              value={data.da || ''}
              onChange={handleChange}
              placeholder="DA"
            />
            <input
              type="text"
              name="travelAllowance"
              value={data.travelAllowance || ''}
              onChange={handleChange}
              placeholder="Travel Allowance"
            />
            <input
              type="text"
              name="businessIncentive"
              value={data.businessIncentive || ''}
              onChange={handleChange}
              placeholder="Business Incentive"
            />
          </div>
          <div className="deductions">
            <h3 className='c-subtit'>Deductions</h3>
            <input
              type="text"
              name="providentFund"
              value={data.providentFund || ''}
              onChange={handleChange}
              placeholder="Provident Fund"
              readOnly
            />
          </div>
        </div>
        <div className="summary">
          <h3 className='c-subtit'>Net Pay for the month</h3>
          <div className="net-pay">
            <h3 className='c-subtitle'>Net Pay: {data.netPay || '0.00'}</h3>
            <h4 className='c-subtitle'>Amount in Words: {data.amountInWords || 'Zero'}</h4>
          </div>
        </div>
        <div className='s'>
        <div className="signature">
          <p>Employer Signature: __________________</p>
        </div><div className="signature">
          <p>Employee's Signature: ________________</p>
        </div>
        </div>
        
      </div> */}
       {/* <div className="payslip">
        <h1 className='c-tit'>Vetri It Systems</h1>
        <div className="header">
          <div className="company-info">
            <div className='company-logo'>
              <img src={Vislogo} alt="Company Logo" />
            </div>
            <div className='company-details'>
              <p className='comp-para'><span className='com-span'>Address:</span> Vetri It Park, Surandai</p>
              <p className='comp-para'><span className='com-span'>Contact No:</span> 91 8438558527</p>
              <p className='comp-para'><span className='com-span'>Website:</span> www.vetriit.com </p>
            </div>
          </div>
        </div>
        <h2 className='c-subtit'>
          Pay slip for the month of{' '}
          <input
            type="month"
            name="salaryMonth"
            value={data.salaryMonth || ''}
            onChange={handleChange}
          />
        </h2>
        <div className="employee-details">
          <div className="employee-details-col">
            <input
              type="text"
              name="employeeName"
              value={data.employeeName || ''}
              onChange={handleChange}
              placeholder="Employee Name"
            />
            <input
              type="text"
              name="designation"
              value={data.designation || ''}
              onChange={handleChange}
              placeholder="Designation"
            />
            <input
              type="text"
              name="department"
              value={data.department || ''}
              onChange={handleChange}
              placeholder="Department"
            />
            <input
              type="text"
              name="location"
              value={data.location || ''}
              onChange={handleChange}
              placeholder="Location"
            />
            <input
              type="text"
              name="employeeId"
              value={data.employeeId || ''}
              onChange={handleChange}
              placeholder="Employee ID"
            />
          </div>
          <div className="employee-details-col">
            <input
              type="text"
              name="bankName"
              value={data.bankName || ''}
              onChange={handleChange}
              placeholder="Bank Name"
            />
            <input
              type="text"
              name="bankAccountNumber"
              value={data.bankAccountNumber || ''}
              onChange={handleChange}
              placeholder="Bank Account Number"
            />
            <input
              type="text"
              name="pan"
              value={data.pan || ''}
              onChange={handleChange}
              placeholder="PAN"
            />
            <input
              type="text"
              name="epfNumber"
              value={data.epfNumber || ''}
              onChange={handleChange}
              placeholder="EPF Number"
            />
            <input
              type="text"
              name="esiNumber"
              value={data.esiNumber || ''}
              onChange={handleChange}
              placeholder="ESI Number"
            />
            <input
              type="text"
              name="uanNumber"
              value={data.uanNumber || ''}
              onChange={handleChange}
              placeholder="UAN Number"
            />
          </div>
        </div>
        <div className="salary-info">
          <h3 className='c-subtit'>Salary Information</h3>
          <input
            type="number"
            name="numberOfWorkingDays"
            value={data.numberOfWorkingDays || ''}
            onChange={handleChange}
            placeholder="Number of Working Days"
          />
          <input
            type="number"
            name="perDaySalary"
            value={data.perDaySalary || ''}
            onChange={handleChange}
            placeholder="Per Day Salary"
          />
          
        </div>
        <div className="earnings-deductions">
          <div className="earnings">
            <h3 className='c-subtit'>Earnings</h3>
            <input
              type="text"
              name="basic"
              value={data.basic || ''}
              onChange={handleChange}
              placeholder="Basic"
              readOnly
            />
            <input
              type="text"
              name="hra"
              value={data.hra || ''}
              onChange={handleChange}
              placeholder="HRA"
            />
            <input
              type="text"
              name="da"
              value={data.da || ''}
              onChange={handleChange}
              placeholder="DA"
            />
            <input
              type="text"
              name="travelAllowance"
              value={data.travelAllowance || ''}
              onChange={handleChange}
              placeholder="Travel Allowance"
            />
            <input
              type="text"
              name="businessIncentive"
              value={data.businessIncentive || ''}
              onChange={handleChange}
              placeholder="Business Incentive"
            />
          </div>
          <div className="deductions">
            <h3 className='c-subtit'>Deductions</h3>
            <input
              type="text"
              name="advanceSalary"
              value={data.advanceSalary || ''}
              onChange={handleChange}
              placeholder="Advance Salary"
              readOnly
            />
            <input
            type="number"
            name="leaveDayRupees"
            value={data.leaveDayRupees || ''}
            onChange={handleChange}
            placeholder="Leave Day Rupees"
          />
         
          </div>
        </div>
        <div className="summary">
          <h3 className='c-subtit'>Net Pay for the month</h3>
          <div className="net-pay">
            <h3 className='c-subtitle'>Net Pay: {data.netPay || '0.00'}</h3>
            <h4 className='c-subtitle'>Amount in Words: {data.amountInWords || 'Zero'}</h4>
          </div>
        </div>
        <div className='s'>
          <div className="signature">
            <p>Employer Signature: __________________</p>
          </div>
          <div className="signature">
            <p>Employee's Signature: ________________</p>
          </div>
        </div>
      </div>
    </div> */}
    <div className="payslip">
      <div className="header">
        <div className="company-info">
          <div className='company-logo'>
            <img src={Vislogo} alt="Company Logo" />
          </div>
          <div className='company-details'>
          <h1 className='c-tit'>Vetri It Systems</h1>
          <p className='comp-para'>Vetri IT Park</p>
          <p className='comp-para'>Surandai</p>
          </div>
        </div>
      </div>
      <h2 className='c-subtit'>
        Pay slip for the month of{' '}
        <input
          type="month"
          name="salaryMonth"
          value={data.salaryMonth || ''}
          onChange={handleChange}
        />
      </h2>
      <div className="employee-details">
        <div className="employee-details-col">
          <input
            type="text"
            name="employeeName"
            value={data.employeeName || ''}
            onChange={handleChange}
            placeholder="Employee Name"
          />
          <input
            type="text"
            name="designation"
            value={data.designation || ''}
            onChange={handleChange}
            placeholder="Designation"
          />
          <input
            type="text"
            name="department"
            value={data.department || ''}
            onChange={handleChange}
            placeholder="Department"
          />
          <input
            type="text"
            name="location"
            value={data.location || ''}
            onChange={handleChange}
            placeholder="Location"
          />
          <input
            type="text"
            name="employeeId"
            value={data.employeeId || ''}
            onChange={handleChange}
            placeholder="Employee ID"
          />
        </div>
        <div className="employee-details-col">
          <input
            type="text"
            name="bankName"
            value={data.bankName || ''}
            onChange={handleChange}
            placeholder="Bank Name"
          />
          <input
            type="text"
            name="bankAccountNumber"
            value={data.bankAccountNumber || ''}
            onChange={handleChange}
            placeholder="Bank Account Number"
          />
          <input
            type="text"
            name="pan"
            value={data.pan || ''}
            onChange={handleChange}
            placeholder="PAN"
          />
          <input
            type="text"
            name="epfNumber"
            value={data.epfNumber || ''}
            onChange={handleChange}
            placeholder="EPF Number"
          />
          <input
            type="text"
            name="esiNumber"
            value={data.esiNumber || ''}
            onChange={handleChange}
            placeholder="ESI Number"
          />
          <input
            type="text"
            name="uanNumber"
            value={data.uanNumber || ''}
            onChange={handleChange}
            placeholder="UAN Number"
          />
        </div>
      </div>
      <div className="salary-info">
        <h3 className='c-subtit'>Salary Information</h3>
        <input
          type="number"
          name="numberOfWorkingDays"
          value={data.numberOfWorkingDays || ''}
          onChange={handleChange}
          placeholder="Number of Working Days"
        />
        <input
          type="number"
          name="perDaySalary"
          value={data.perDaySalary || ''}
          onChange={handleChange}
          placeholder="Per Day Salary"
        />
      </div>
      <div className="earnings-deductions">
        <div className="earnings">
          <h3 className='c-subtit'>Earnings</h3>
          <input
            type="text"
            name="basic"
            value={data.basic || ''}
            onChange={handleChange}
            placeholder="Basic"
            readOnly
          />
          <input
            type="text"
            name="hra"
            value={data.hra || ''}
            onChange={handleChange}
            placeholder="HRA"
          />
          <input
            type="text"
            name="da"
            value={data.da || ''}
            onChange={handleChange}
            placeholder="DA"
          />
          <input
            type="text"
            name="travelAllowance"
            value={data.travelAllowance || ''}
            onChange={handleChange}
            placeholder="Travel Allowance"
          />
          <input
            type="text"
            name="businessIncentive"
            value={data.businessIncentive || ''}
            onChange={handleChange}
            placeholder="Business Incentive"
          />
        </div>
        <div className="deductions">
          <h3 className='c-subtit'>Deductions</h3>
        
          <input
            type="text"
            name="leaveDayRupees"
            value={data.leaveDayRupees || ''}
            onChange={handleChange}
            placeholder="Leave Day Rupees"
            readOnly
          />
          <input
            type="text"
            name="advanceSalary"
            value={data.advanceSalary || ''}
            onChange={handleChange}
            placeholder="Advance Salary"
          />
        </div>
      </div>
      <div className="summary">
        <h3 className='c-subtit'>Net Pay for the month</h3>
        <div className="net-pay">
          <h3 className='c-subtitle'>Net Pay: {data.netPay || '0.00'}</h3>
          <h4 className='c-subtitle'>Amount in Words: {data.amountInWords || 'Zero'}</h4>
        </div>
      </div>
      <div className='s'>
        <div className="signature">
          <p>Employer Signature: __________________</p>
        </div><div className="signature">
          <p>Employee's Signature: ________________</p>
        </div>
        </div>
    </div>

    </div>
  );
};
export default PayslipTemplateVIS;
