import React from 'react';
import './Paysliptemplate.css';
import './Preview.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Vislogo from './images/vetri.png';
import axios from 'axios';

const PayslipTemplateVISPreview = ({ data }) => {
  const printContent = () => {
    const input = document.querySelector('.payslip');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>Print Payslip</title>');
        printWindow.document.write('<style>body { margin: 0; padding: 0; }</style>');
        printWindow.document.write('</head><body>');
        printWindow.document.write(`<img src="${imgData}" style="width: 100%;" />`);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.focus();
        printWindow.onload = function () {
          printWindow.print();
        };
      })
      .catch((error) => console.error('Error capturing the content: ', error));
  };

  const handleDownload = () => {
    const input = document.querySelector('.payslip');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
  
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
  
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
  
        // Create a file name based on employee name and salary month
        const fileName = `${data.employeeName}-${data.salaryMonth}-Payslip.pdf`;
  
        pdf.save(fileName);
      })
      .catch((error) => console.error('Error capturing the content: ', error));
  };
  
  const handleSubmit = async () => {
    try {
      const response = await axios.post('https://vetripayslip.com/api/VIS', data);
      console.log('Payslip data saved:', response.data);
      alert('Payslip data successfully saved to the database!');
    } catch (error) {
      console.error('There was an error saving the payslip data:', error);
      alert('Failed to save payslip data.');
    }
  };
  return (
    <div className="preview">
      <div className="payslip">

        <div className="header">
          <div className="company-info">
            <div className='company-logo'>
              <img src={Vislogo} alt="Company Logo" />
            </div>
            <div className='company-details'>
            <h1 className='c-tit'>Vetri It Systems</h1>
            <p className='comp-para'>Vetri IT Park</p>
            <p className='comp-para'>Surandai</p>
            </div>
          </div>
        </div>

        <h2 className='c-subtit'>Pay slip for the month of {data.salaryMonth}</h2>
       
     

        <div className="employee-details">
  <table>
    <tbody>
      <tr>
        <td><strong>Employee Name:</strong> {data.employeeName}</td>
        <td><strong>Bank Name:</strong> {data.bankName}</td>
      </tr>
      <tr>
        <td><strong>Designation:</strong> {data.designation}</td>
        <td><strong>Bank Account Number:</strong> {data.bankAccountNumber}</td>
      </tr>
      <tr>
        <td><strong>Department:</strong> {data.department}</td>
        <td><strong>PAN:</strong> {data.pan}</td>
      </tr>
      <tr>
      <td><strong>Location:</strong> {data.location}</td>
        <td><strong>EPF Number:</strong> {data.epfNumber}</td>

      </tr>
      <tr>
      <td><strong>Employee ID:</strong> {data.employeeId}</td>
      <td><strong>ESI Number:</strong> {data.esiNumber}</td>

      </tr>
      <tr>
      <td></td>
      <td><strong>UAN Number:</strong> {data.uanNumber}</td>
      </tr>
    </tbody>
  </table>
</div>

<div className="salary-info">
  <h3 className='c-subtit'>Salary Information</h3>
  <table>
    <tbody>
      <tr>
        <td><strong>Number of Working Days:</strong> {data.numberOfWorkingDays}</td>
      </tr>
    </tbody>
  </table>
</div>

<div className="earnings-deductions">
  <table>
    <tbody>
      <tr>
        <td className="earnings">
          <h4 className='c-subtit'>Earnings</h4>
           <table>
            <tbody>
              <tr>
                <td><strong>Basic:</strong> {data.basic}</td>
              </tr>
              <tr>
                <td><strong>HRA:</strong>{data.hra}</td>
              </tr>  <tr>
                <td><strong>DA:</strong>{data.da}</td>
              </tr>
              <tr>
                <td><strong>Travel Allowance:</strong> {data.travelAllowance}</td>
              </tr>
              <tr>
                <td><strong>Business Incentive:</strong> {data.businessIncentive}</td>
              </tr>
            </tbody>
          </table>
        </td>
        <td className="deductions">
          <h4 className='c-subtit'>Deductions</h4>
          <table>
            <tbody>
              <tr>
                <td><strong>Loss of Pay:</strong> {data.leaveDayRupees}</td>
              </tr>
              <tr>
                <td><strong>Advance Salary:</strong> {data.advanceSalary}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div className="summary">
          <h3 className='c-subtit'>Net Pay for the month</h3>
          <div className="net-pay">
            <h3 className='c-subtitle'>Net Pay: {data.netPay || '0.00'}</h3>
            <h4 className='c-subtitle'>Amount in Words: {data.amountInWords || 'Zero'}</h4>
          </div>
        </div>

        <div className='s'>
          <div className="signature">
            <p>Employer Signature: __________________</p>
          </div>
          <div className="signature">
            <p>Employee's Signature: ________________</p>
          </div>
        </div>
      </div>
      <div className="button-group">
        <button onClick={handleDownload}>Download</button>
        <button onClick={printContent}>Print</button>
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default PayslipTemplateVISPreview;
