import React from 'react';
import { useLocation } from 'react-router-dom';
import PayslipTemplateVTSPreview from './vtsPreview';
import PayslipTemplateVISPreview from './visPreview';
import PayslipTemplateVetriPreview from './vaPreview';

const PreviewPage = () => {
  const location = useLocation();
  const data = location.state?.data || {};
  const templateType = location.state?.templateType || 'VIS';

  let PreviewComponent;
  switch (templateType) {
    case 'VTS':
      PreviewComponent = PayslipTemplateVTSPreview;
      break;
    case 'VIS':
      PreviewComponent = PayslipTemplateVISPreview;
      break;
    case 'Vetri':
      PreviewComponent = PayslipTemplateVetriPreview;
      break;
    default:
      PreviewComponent = PayslipTemplateVISPreview;
      break;
  }

  return (
    <div>
      <PreviewComponent data={data} />
    </div>
  );
};

export default PreviewPage;
